<template>
  <div>
    <div v-if="!isLoading">
      <b-card>
        <template #header>
          <h4>Statistics</h4>
        </template>
        <b-row>
          <statistic-card-horizontal
            icon="ActivityIcon"
            :statistic="statistics.totalOffers"
            statistic-title="Total Requests"
          />
          <statistic-card-horizontal
            color="success"
            icon="UserCheckIcon"
            :statistic="statistics.publishedOffers"
            statistic-title="Published Requests"
          />
          <statistic-card-horizontal
            color="success"
            icon="UserCheckIcon"
            :statistic="statistics.approvedOffers"
            statistic-title="Approved Requests"
          />
          <statistic-card-horizontal
            color="warning"
            icon="UserXIcon"
            :statistic="statistics.declinedOffers"
            statistic-title="Declined Requests"
          />
          <statistic-card-horizontal
            color="warning"
            icon="UserMinusIcon"
            :statistic="statistics.pendingOffers"
            statistic-title="Pending Requests"
          />
        </b-row></b-card>
    </div>
    <b-modal
      id="reason-modal"
      centered
      title="Rejection Reason"
      hide-footer
    >
      <reason-modal
        :given-reason="givenReason"
        :reason-only="reasonOnly"
        @submit-reason="handleDecline"
      />
    </b-modal>
    <b-card header="Search">
      <b-form-row>
        <b-col md="6">
          <b-form-group
            label="Name"
            label-for="search"
          >
            <search
              placeholder="Offer Title"
              :search-query.sync="query.search"
              @refresh="$refs.table.refresh()"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
        >
          <b-form-group
            label="Duration"
            label-for="date-range-filter"
          >
            <date-range-filter :date-range.sync="query.range" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <custom-vue-select
            id="status"
            label="Status"
            placeholder="Status"
            :value-reducer="option=>option.value"
            :options="[
              {name:'All',value:null},
              {name:'Published',value:'published'},
              {name:'Pending',value:'pending'},
              {name:'Approved',value:'approved'},
              {name:'Declined',value:'decline'},
            ]"
            :selected.sync="query.suggestion_status"
            text-field="name"
          />
        </b-col>
        <b-col md="6">
          <offer-category-select
            :offer-category.sync="query.category"
          />
        </b-col>
        <b-col
          md="2"
          class="ml-auto"
        >
          <search-button @refresh="$refs.table.refresh()" />
        </b-col>
      </b-form-row>
    </b-card>
    <b-card>
      <b-table
        ref="table"
        :items="getOffers"
        :fields="fields"
        :current-page="pagination.currentPage"
        :per-page="pagination.perPage"
        responsive
        show-empty
      >
        <template #cell(title)="{item}">
          <b-link :to="{name:'suggested-offer-view',params:{id:item.id} }">
            {{ item.title }}
          </b-link>
        </template>
        <template #cell(actions)="{item}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <b-dropdown-item :to="{name:'original-suggested-offer-view',params:{id:item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View Original Request</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{name:'suggested-offer-view',params:{id:item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>
            <b-dropdown-item :to="{name:'suggested-offers-notes',params:{id:item.id} }">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Notes</span>
            </b-dropdown-item>
            <template v-if="item.suggestion_status==='decline'">
              <b-dropdown-item @click="showDeclineModal(item, true)">
                <feather-icon icon="AlertTriangleIcon" />
                <span class="align-middle ml-50">View Rejection Reason</span>
              </b-dropdown-item>
            </template>
            <template v-if="item.suggestion_status==='pending'">
              <b-dropdown-item :to="{name:'suggested-offers-edit',params:{id:item.id} }">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="toggleSuggest(item, 'approved')">
                <div>
                  <feather-icon icon="CheckIcon" />
                  <span
                    class="align-middle ml-50"
                  >Approve</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="showDeclineModal(item)">
                <div class="text-danger">
                  <feather-icon icon="TrashIcon" />
                  <span
                    class="align-middle ml-50"
                  >Decline</span>
                </div>
              </b-dropdown-item>
            </template>
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <pagination
      :total-rows="pagination.totalRows"
      :per-page="pagination.perPage"
      :current-page.sync="pagination.currentPage"
    />
  </div>
</template>
<script>
import Offers from '@/common/compositions/Offers/OffersApi'
import paginationData from '@/common/compositions/common/paginationData'
import Pagination from '@/common/components/common/Table/Pagination.vue'
import Search from '@/common/components/common/Table/Search.vue'
import DateRangeFilter from '@/common/components/common/Table/DateRangeFilter.vue'
import SearchButton from '@/common/components/common/Table/SearchButton.vue'
import OfferCategorySelect from '@/common/components/GeneralSettings/offer-categories/OfferCategorySelect.vue'
import handleAlerts from '@/common/compositions/common/handleAlerts'
import StatisticCardHorizontal from '@/@core/components/statistics-cards/StatisticCardHorizontal.vue'
import ReasonModal from '@/common/components/Offers/ReasonModal.vue'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'OffersList',
  components: {
    Pagination,
    Search,
    DateRangeFilter,
    SearchButton,
    OfferCategorySelect,
    StatisticCardHorizontal,
    ReasonModal,
    CustomVueSelect,
  },
  data() {
    return {
      query: {
        search: '',
        range: [],
        tagIds: [],
        category: {},
        suggestion_status: null,
      },
      statistics: {},
      isLoading: true,
      declineItem: null,
      toggleData: {},
      reasonOnly: null,
      givenReason: '',
    }
  },
  computed: {
    fields() {
      return [
        { key: 'id', sortable: true },
        { key: 'title', sortable: true },
        { key: 'description', sortable: true },
        { key: 'start_date', sortable: true },
        { key: 'end_date', sortable: true },
        { key: 'category', formatter: v => v?.name, sortable: true },
        { key: 'type' },
        { key: 'suggestion_status' },
        'actions',
      ]
    },
    formattedQuery() {
      return {
        search: this.query.search,
        store: this.query.store,
        suggestion_status: this.query.suggestion_status,
        startTime: this.query.range[0],
        endTime: this.query.range[1],
        categoryId: this.query.category?.id,
        classification: 'suggest',
      }
    },
  },
  setup() {
    const { pagination } = paginationData()
    const { successfulOperationAlert } = handleAlerts()
    const {
      listSuggestedOffersRequest, toggleSuggestedOffersRequest, getOfferStatistics,
    } = Offers()
    return {
      pagination, listSuggestedOffersRequest, toggleSuggestedOffersRequest, successfulOperationAlert, getOfferStatistics,
    }
  },
  mounted() {
    this.setStats()
  },
  methods: {
    getOffers(ctx) {
      return this.listSuggestedOffersRequest({
        ...this.formattedQuery,
        page: this.pagination.currentPage,
        orderParameter: ctx.sortBy,
        orderDir: ctx.sortDesc ? 'DESC' : 'ASC',
      }).then(res => {
        this.pagination.totalRows = res.data.pagination.total
        return res.data.data || []
      })
    },
    toggleSuggest(item, newStatus) {
      this.toggleData.suggest_status = newStatus
      this.toggleSuggestedOffersRequest(item.id, this.toggleData).then(() => {
        const message = newStatus === 'approved' ? 'Offer is approved successfully' : 'Offer is declined successfully'
        this.successfulOperationAlert(message)
        item.suggestion_status = newStatus
        this.$refs.table.refresh()
      })
    },
    setStats() {
      this.getOfferStatistics().then(res => {
        this.statistics = res.data.data
        this.isLoading = false
      })
    },
    showDeclineModal(item, reasonOnly = false) {
      this.reasonOnly = reasonOnly
      this.givenReason = item.reject_reason
      this.declineItem = item
      this.$bvModal.show('reason-modal')
    },
    handleDecline(reason) {
      this.toggleData.reject_reason = reason
      this.toggleSuggest(this.declineItem, 'decline')
      this.$bvModal.hide('reason-modal')
    },
  },
}
</script>
<style lang="">

</style>
